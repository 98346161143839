<script lang="ts" setup></script>

<template>
  <div class="pdf-layout">
    <slot />
  </div>
</template>

<style lang="scss" scoped>

</style>
